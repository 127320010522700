/**
 * Dependencies
 */
import {polaris} from "./config";
import {submitForm, fetchAPI, fetchData, deleteAPI, capital, dataDir, setSummernote} from "./core";
import {blogSettings, colorSettings} from "./settings";


/**
 * Global variables
 */
let navStorage = window.localStorage;


/**
 * Admin App APIs
 */
export const adminAPIs = () => {
	// Aside Navigator
	toggleAside();

	// Aside tabs
	asideTabs();

	// Admin nav
	adminNav();

	// Expand and shrink nav
	resizeNav();
	
	// Language modal
	langModal();

	// Delete page
	deletePage();

	// Manage SEO
	manageSEO();

	// Manage Partials
	managePartials();

	// Add & Edit Page
	addEditPage();
	
	// Admin charts
	adminCharts();

	// Copy code
	copyCode();

	// Add & edit plugin
	addEditPlugin();

	// Delete plugin
	deletePlugin();
	
	// Upload slideshow (media)
	uploadSlideshow();

	// Create slideshow (custom)
	createSlideshow();
	
	// Delete slideshow
	deleteSlideshow();

	// Edit slideshow
	editSlideshow();
	
	// Upload splide (media)
	uploadSplide();

	// Create splide (custom)
	createSplide();
	
	// Delete splide
	deleteSplide();

	// Edit splide
	editSplide();

	// Manage menu
	manageMenu();

	// Category posts count
	catPostsCount();

	// Manage blog tags
	blogTags();

	// Add blog category
	addBlogCat();

	// Delete blog category
	deleteBlogCat();

	// Edit blog category
	editBlogCat();

	// Add & edit Post
	addEditPost();

	// Delete blog post
	deleteBlogPost();

    // Blog settings
	blogSettings();

    // Color settings
    colorSettings();
};


/**
 * Shows and hides aside
 */
const toggleAside = (key = 'aside') => {
	const aside = document.querySelector(`#${key}`);
	const tgl   = document.querySelector(`#toggle-${key}`);

    // Check selectors
    if (aside && tgl) {
        // Toggle aside
		tgl.onclick = () => {
			// Hide aside
            if (aside.classList.contains("open")) {
				// Aside
                aside.classList.remove("open");
                aside.classList.add("close");

				// Toggler
                tgl.classList.remove("close");
                tgl.classList.add("open");
            }
			// Show aside
            else {
				// Aside
                aside.classList.remove("close");
                aside.classList.add("open");

				// Toggler
                tgl.classList.remove("open");
                tgl.classList.add("close");
            }
		};

    }
};


/**
 * Aside tabs
 */
const asideTabs = () => {
	const btns = document.querySelectorAll('#aside-btns li a');
	if (btns) {
		btns.forEach(btn => {
			btn.onclick = () => {
				const activeBtn = document.querySelector('#aside-btns li a.active');
				const dataTab 	= btn.dataset.tab;
				const activeTab = document.querySelector('#aside-tabs div.active');
				const tabs      = document.querySelectorAll('#aside-tabs div');
				const dataEnter = document.querySelector('#aside-tabs').dataset.enter;
				const dataExit  = document.querySelector('#aside-tabs').dataset.exit;

				// Hide tab
				if (btn == activeBtn) {
					polaris.animation(document.querySelector('#aside-tabs'), dataExit).then(() => {
						btn.classList.remove('active');

						// Current tab
						tabs.forEach(tab => {
							if (tab.dataset.tab === dataTab) {
								tab.classList.remove('active');
								tab.classList.add('display-none');
							}
						});
					});
				}
				// Show tab
				else {
					// Toggle button
					if (activeBtn) activeBtn.classList.remove('active');
					btn.classList.add('active');

					// Toggle tab
					if (activeTab) {
						activeTab.classList.remove('active');
						activeTab.classList.add('display-none');
					}
					else {
						polaris.animation(document.querySelector('#aside-tabs'), dataEnter);
					}

					// Current tab
					tabs.forEach(tab => {
						if (tab.dataset.tab === dataTab) {
							tab.classList.remove('display-none');
							tab.classList.add('active');
						}
					});
				}

				// Prevent default behavior
				return false;
			};
		});
	}
};


/**
 * Handles admin nav
 */
const adminNav = () => {
	const nav = document.querySelector('#admin-nav');
	if (nav) {
		const menus   = document.querySelectorAll('#admin-nav > li > a');
		const subs    = document.querySelectorAll('#admin-nav ul a');
		let active 	  = document.querySelector('#admin-nav > li > a.active');
		let activeSub = document.querySelector('#admin-nav ul a.active');
		let openMenu  = nav.querySelector('li.open');

		// Handle menus
		menus.forEach(menu => {
			// Check URL (page load)
			if (menu.getAttribute('href') == polaris.href()) {
				// Remove previous active menu
				if (active)	active.classList.remove('active');

				// New menu
				if (openMenu && openMenu != menu) {
					// Remove active submenu
					if (activeSub) activeSub.classList.remove('active');

					// Close submenu
					openMenu.style.removeProperty('max-height');
					openMenu.classList.remove('open');
				}

				// Active new menu
				menu.classList.add('active');
			}

			// Handle click
			menu.onclick = () => {
				active 		  = document.querySelector('#admin-nav > li > a.active');
				activeSub 	  = document.querySelector('#admin-nav ul a.active');
				openMenu      = nav.querySelector('li.open');
				const parent  = menu.parentElement;
				const submenu = parent.querySelector('ul');
				const link    = menu.getAttribute('href');
				let maxHeight;

				// Has submenu
				if (submenu) {
					// Open submenu
					if (!parent.classList.contains('open')) {
						maxHeight = parseFloat(polaris.getStyle(parent, 'height')) + parseFloat(polaris.getStyle(submenu, 'height')) + "px";
						parent.style.setProperty('max-height', maxHeight);

						// Open menu
						parent.classList.add('open');
					}
					// Close submenu
					else {
						openMenu.style.removeProperty('max-height');
						openMenu.classList.remove('open');
					}
				}

				// Check link
				// if (link !== '#' && menu !== active) {
				if (link !== '#' && menu !== active) {
					menu.classList.add('active');

					// Remove previous active menu
					if (active)	active.classList.remove('active');

					// Remove active submenu
					if (activeSub) activeSub.classList.remove('active');
				}

				// New menu
				if (openMenu && openMenu != parent) {
					openMenu.style.removeProperty('max-height');
					openMenu.classList.remove('open');
				}

				// Prevent default behavior
				if (link === '#') return false;
			};
		});

		// Handle submenus
		subs.forEach(sub => {
			// Check URL (page load)
			if (sub.getAttribute('href') == polaris.href()) {
				// Remove previous active classes
				if (active)	active.classList.remove('active');
				if (activeSub) activeSub.classList.remove('active');

				// Add new active classes
				sub.classList.add('active');
				sub.parentElement.parentElement.parentElement.querySelector('a').classList.add('active');
			}

			// Handle click
			sub.onclick = () => {
				active 	   = document.querySelector('#admin-nav > li > a.active');
				activeSub  = document.querySelector('#admin-nav ul a.active');
				const menu = sub.parentElement.parentElement.parentElement.querySelector('a');
				const link = sub.getAttribute('href');

				// Check submenu link
				if (link !== '#') {
					if (menu !== active) {
						menu.classList.add('active');

						// Remove previous active menu
						if (active)	active.classList.remove('active');
					}

					// Remove previous submenu active class
					if (activeSub) activeSub.classList.remove('active');

					// Add active class to the new submenu
					sub.classList.add('active');
				}

				// Prevent default behavior
				if (link === '#') return false;
			};
		});
	}
};


/**
 * Expands and shrinks nav
 */
const resizeNav = () => {		
	const tgl = document.querySelector('#toggle-navs');
	if (tgl) {
		const icon = tgl.querySelector('i');
		const nav  = document.querySelector('.admin-nav');
		const subs = document.querySelectorAll('.admin-nav li ul');
		let size, timeout;

		// Has nav storage
		if (navStorage.getItem("nav-size")) {
			size = navStorage.getItem("nav-size");

			// Expand
			if (size === 'expand') {
				// Toggler
				icon.classList.remove('fa-expand');
				icon.classList.add('fa-compress');

				// Nav
				nav.classList.add('expand');
			}
			// Shrink
			else if (size === 'shrink') {
				// Toggler
				icon.classList.remove('fa-compress');
				icon.classList.add('fa-expand');

				// Nav
				nav.classList.remove('expand');
			}
		}
		// No nav storage
		else {
			// Expand
			if (nav.classList.contains("expand")) {
				// Toggler
				icon.classList.remove('fa-expand');
				icon.classList.add('fa-compress');
			}
			// Shrink
			else {
				// Toggler
				icon.classList.remove('fa-compress');
				icon.classList.add('fa-expand');
			}
		}

		// Handle click
		tgl.onclick = () => {
			// Expand
			if (icon.classList.contains('fa-expand')) {
				// Toggler
				icon.classList.remove('fa-expand');
				icon.classList.add('fa-compress');

				// Nav
				nav.classList.add('expand');

				// Update the size
				size = 'expand';

				// Remove submenu animations
				subs.forEach(sub => {
					sub.classList.remove('animation');
				});
			}
			// Shrink
			else if (icon.classList.contains('fa-compress')) {
				// Toggler
				icon.classList.remove('fa-compress');
				icon.classList.add('fa-expand');

				// Nav
				nav.classList.remove('expand');

				// Update the size
				size = 'shrink';

				// Add submenu animations
				clearTimeout(timeout);
				timeout = setTimeout(() => {
					subs.forEach(sub => {
						sub.classList.add('animation');
					});
				}, 500);
			}

            // Set skin storage
			navStorage.setItem("nav-size", size);

            // Prevent default behavior
            return false;
		};
	}
};


/**
 * Language modal
 */
const langModal = () => {
	let selector = document.querySelector("#language-modal");
    if (selector) {
		selector.onclick = () => {
			let title = "Change Language";
			let body = '<div id="lang-list">Loading...</div>';

			polaris.modal(title, body, "", "xs", "zoomIn", "zoomOut", "", true, true, 500);

			let api = selector.dataset.api;

			// Language API
			fetch(api, {
				method: 'put',
				headers: new Headers({
					'Content-Type': 'application/json'
				}),
				body: JSON.stringify({
					langs_api: true,
					app:  	   'admin'
				})
			})
			.then(response => response.json())
			.then(result => {
				let langs = eval(result.langs);

				// Produce modal content
				let content = '<ul class="ul ul-y row mb-sm-c">';
				for (let lang of langs) {
					content += `
						<li class="col col-3">
							<a href="/${lang.code}/${result.app}/">${lang.name}</a>
						</li>
					`;
				}
				content += '</ul>';

				// Update modal content
				document.querySelector("#lang-list").innerHTML = content;
			});
		};
    }
};


/**
 * Delete page
 */
const deletePage = () => {
	if (document.querySelector("#manage-page")) {
		let parent = document.querySelector("#manage-page");
		let selectors = parent.querySelectorAll(".delete-page");

		if (selectors.length > 0) {
			selectors.forEach((selector) => {
				selector.onclick = () => {
					let id = selector.getAttribute("data-id");
					let tr = parent.querySelector(`tr[data-id='${id}']`);

					// Start the delete process
					fetchAPI(parent, selector, {delete_page: true, id: id})
					.then((result) => {
						// On success
						if (result.status) {
							// Remove the page from the table
							polaris.remove(tr);
	
							// Find trs length
							let trs = document.querySelectorAll("#manage-page tr");
	
							// Check trs length
							if (trs.length == 1) {
								let trParent = document.querySelector("#manage-page tbody");
	
								// Append tr
								polaris.append('tr', trParent, '<td class="text-center font-500" colspan="5">No page found!</td>');
							}
						}
					});
				}
			});
		}
	}
};


/**
 * Manage SEO
 */
const manageSEO = () => {
	const form = document.querySelector('#manage-seo');
	if (form) {
		form.onsubmit = () => {
            // Handle form submition
            submitForm(form);

            // Prevent default behavior
            return false;
		};
	}
};


/**
 * Manage partials
 */
const managePartials = () => {
	const form = document.querySelector('#manage-partial');
	if (form) {
		const pageAPI  	     = document.querySelector("#page-api");
		const resetPartials  = document.querySelectorAll(".reset-partial");
		const devOptions	 = document.querySelectorAll(".developer-options");

		// Reset partials
		resetPartials.forEach(elem => {
			elem.onclick = () => {
				const partial = elem.getAttribute("data-partial");
	
				// Fetch API
				fetchAPI(pageAPI, elem, {resetPartialTemplate: partial})
				.then((result) => {
					// On success
					if (result.status) {
						// Reset partial source & options
						document.querySelector(`#${partial}-source`).value = result.data.source;

						// Close partial popup
						document.querySelector(`#${partial}-popup`).classList.remove("popup-open");

						// Reset summernote
						document.querySelectorAll('.summernote').forEach(el => {
							if (el == document.querySelector(`#${partial}-source`)) {
								$(el).summernote('code', result.data.source);
							}
						});
					}
				});
			}
		});

		// Developer Options
		devOptions.forEach((elem) => {
			elem.onclick = () => {
				const partial = elem.getAttribute("data-partial");
				const options = document.querySelector(`#${partial}-options`);
				const title   = `${capital(partial)} Developer Settings`;
				let body;

				// Check language direction
				if (dataDir === 'ltr') {
					body  = `
					<div class="popup__control mb-md">
						<a href="#" class="px-xs">Reset?</a>
						<div class="popup popup-right popup-lg popup-click text-center" id="options-popup">
							<button type="button" class="btn btn-success btn-xs mb-sm mt-sm popup--close mr-xs" style="min-width: 40px;">NO</button>
							<button type="button" class="btn btn-danger btn-xs mb-sm mt-sm ml-xs" id="reset-options" style="min-width: 40px;">YES</button>
						</div>
					</div>
					<textarea class="field field-focus scroll scroll-sm scroll-round font-mono font-lg" id="save-options" rows="9">${options.value}</textarea>
					`;
				}
				else {
					body  = `
					<div class="popup__control mb-md">
						<a href="#" class="px-xs">Reset?</a>
						<div class="popup popup-left popup-lg popup-click text-center" id="options-popup">
							<button type="button" class="btn btn-success btn-xs mb-sm mt-sm popup--close ml-xs" style="min-width: 40px;">NO</button>
							<button type="button" class="btn btn-danger btn-xs mb-sm mt-sm mr-xs" id="reset-options" style="min-width: 40px;">YES</button>
						</div>
					</div>
					<textarea class="field field-focus scroll scroll-sm scroll-round font-mono font-lg" id="save-options" rows="9">${options.value}</textarea>
					`;
				}

				// Open up modal
				polaris.modal(title, body, "", "sm", "zoomIn", "zoomOut", "", true, true, 500);

				// Initialize polaris
				polaris.init();

				// Save partial
				const reset = document.querySelector('#reset-options');
				const save  = document.querySelector('#save-options');
				const popup = document.querySelector('#options-popup');

				// Save options
				save.onchange = () => {
					options.value = save.value;
				};

				// Reset options
				reset.onclick = function() {
					const btnText = this.innerHTML;
		
					// Change button text
					this.innerHTML = "Requesting...";

					// Fetch partial option
					fetch(elem.dataset.action, {
						method: elem.dataset.method,
						headers: new Headers({
							'Content-Type': 'application/json'
						}),
						body: JSON.stringify({resetOptionTemplate: partial})
					})
					.then(response => response.json())
					.then(result => {
						// On success
						if (result.status === "success") {
							save.value = result.options;
							save.onchange();

							// Alert user
							polaris.alert('Options reseted successfully!', "fadeInTop", "fadeOutBottom", "success", "", true, 750, 0);
			
							// Reset button
							this.innerHTML = btnText;
			
							// Close popup
							popup.classList.remove("popup-open");
						}
					});
				};
			};
		});

		// Form submition
		form.onsubmit = () => {
			// Handle form submition
			submitForm(form);

			// Prevent default behavior
			return false;
		};
	}
};


/**
 * Add & Edit Page
 */
const addEditPage = () => {
	const addPage = document.querySelector('#add-page');
	const editPage = document.querySelector('#edit-page');
	if (addPage || editPage) {
		// Source type
		const pageAPI  		  = document.querySelector("#page-api");
		const sourceType 	  = document.querySelector("#source-type");
		const switchAccess    = document.querySelector("#switch-access");
		const isCustom 	      = document.querySelector("#is-custom");
 		const pageSeo 	   	  = document.querySelectorAll(".page-seo");
		const cmsSource    	  = document.querySelector("#cms-source");
		const partials 		  = document.querySelectorAll("#cms-source > div");
		const partialSettings = document.querySelector("#partial-settings");
		const switchPartials  = document.querySelectorAll(".switch-partial");
		const resetPartials   = document.querySelectorAll(".reset-partial");
		const devOptions	  = document.querySelectorAll(".developer-options");
		const customSource 	  = document.querySelector("#custom-source");

		// Source Type
		sourceType.onchange = function() {
			// Custom source
			if (this.checked) {
				pageSeo.forEach(elem => {
					elem.classList.add('display-none');
				});
				cmsSource.classList.add('display-none');
				partialSettings.classList.add('display-none');
				customSource.classList.remove('display-none');
				isCustom.value = '1';
			}
			// CMS source
			else {
				pageSeo.forEach(elem => {
					elem.classList.remove('display-none');
				});
				cmsSource.classList.remove('display-none');
				partialSettings.classList.remove('display-none');
				customSource.classList.add('display-none');
				isCustom.value = '0';
			}
		};

		// Switch user access
		switchAccess.onchange = function() {
			document.querySelector("#user-access").value = switchAccess.value;
		};

		// Switch partials
		switchPartials.forEach((elem) => {
			elem.onchange = () => {
				const partial = elem.dataset.partial;
	
				// Partials content
				partials.forEach((source) => {
					if (source.dataset.partial == partial) {
						// Set partial input
						document.querySelector(`#has-${partial}`).value = elem.value;

						// Check partial status
						if (elem.value === '2') {
							source.classList.remove('display-none');
						}
						else {
							source.classList.add('display-none');
						}
					}
				});
			};
		});

		// Reset partials
		resetPartials.forEach(elem => {
			elem.onclick = () => {
				const partial = elem.getAttribute("data-partial");
	
				// Fetch API
				fetchAPI(pageAPI, elem, {resetPartial: partial})
				.then((result) => {
					// On success
					if (result.status) {
						// Reset partial source & options
						document.querySelector(`#${partial}-source`).value = result.data.source;

						// Close partial popup
						document.querySelector(`#${partial}-popup`).classList.remove("popup-open");

						// Reset summernote
						document.querySelectorAll('.summernote').forEach(el => {
							if (el == document.querySelector(`#${partial}-source`)) {
								$(el).summernote('code', result.data.source);
							}
						});
					}
				});
			}
		});

		// Developer Options
		devOptions.forEach((elem) => {
			elem.onclick = () => {
				const partial = elem.getAttribute("data-partial");
				const options = document.querySelector(`#${partial}-options`);
				const title   = `${capital(partial)} Developer Settings`;
				let body;
	
				// Check language direction
				if (dataDir === 'ltr') {
					body  = `
					<div class="popup__control mb-md">
						<a href="#" class="px-xs">Reset?</a>
						<div class="popup popup-right popup-lg popup-click text-center" id="options-popup">
							<button type="button" class="btn btn-success btn-xs mb-sm mt-sm popup--close mr-xs" style="min-width: 40px;">NO</button>
							<button type="button" class="btn btn-danger btn-xs mb-sm mt-sm ml-xs" id="reset-options" style="min-width: 40px;">YES</button>
						</div>
					</div>
					<textarea class="field field-focus scroll scroll-sm scroll-round font-mono font-lg" id="save-options" rows="9">${options.value}</textarea>
					`;
				}
				else {
					body  = `
					<div class="popup__control mb-md">
						<a href="#" class="px-xs">Reset?</a>
						<div class="popup popup-left popup-lg popup-click text-center" id="options-popup">
							<button type="button" class="btn btn-success btn-xs mb-sm mt-sm popup--close ml-xs" style="min-width: 40px;">NO</button>
							<button type="button" class="btn btn-danger btn-xs mb-sm mt-sm mr-xs" id="reset-options" style="min-width: 40px;">YES</button>
						</div>
					</div>
					<textarea class="field field-focus scroll scroll-sm scroll-round font-mono font-lg" id="save-options" rows="9">${options.value}</textarea>
					`;
				}

				// Open up modal
				polaris.modal(title, body, "", "sm", "zoomIn", "zoomOut", "", true, true, 500);

				// Initialize polaris
				polaris.init();

				// Save partial
				const reset = document.querySelector('#reset-options');
				const save  = document.querySelector('#save-options');
				const popup = document.querySelector('#options-popup');

				// Save options
				save.onchange = () => {
					options.value = save.value;
				};

				// Reset options
				reset.onclick = function() {
					const btnText = this.innerHTML;
		
					// Change button text
					this.innerHTML = "Requesting...";

					// Fetch partial option
					fetch(elem.dataset.action, {
						method: elem.dataset.method,
						headers: new Headers({
							'Content-Type': 'application/json'
						}),
						body: JSON.stringify({resetOption: partial})
					})
					.then(response => response.json())
					.then(result => {
						// On success
						if (result.status === "success") {
							save.value = result.options;
							save.onchange();

							// Alert user
							polaris.alert('Options reseted successfully!', "fadeInTop", "fadeOutBottom", "success", "", true, 750, 0);
			
							// Reset button
							this.innerHTML = btnText;
			
							// Close popup
							popup.classList.remove("popup-open");
						}
					});
				};
			};
		});

		// Add page submition
		if (addPage) {
			addPage.onsubmit = () => {
				// Handle form submition
				submitForm(addPage)
				.then((result) => {
					// On success
					if (result.status) {
						// Reset form
						addPage.reset();

						// Reset summernote
						document.querySelectorAll('.summernote').forEach((elem) => {
							$(elem).summernote('reset');
						});

						// Check custom source
						if (sourceType.checked) sourceType.click();

						// User access
						if (switchAccess.value !== '4') {
							switchAccess.value = '4';
							switchAccess.onchange();
						}

						// Switch partials
						switchPartials.forEach(elem => {
							const partial = elem.dataset.partial;
							if (partial === 'main') {
								if (elem.value !== '2') {
									elem.value = '2';
									elem.onchange();
								}
							}
							else {
								if (elem.value !== '1') {
									elem.value = '1';
									elem.onchange();
								}
							}
						});

						// Scroll to top
						document.querySelector('.partials').scrollIntoView();
					}
				});

				// Prevent default behavior
				return false;
			};
		}

		// Edit page
		if (editPage) {
			editPage.onsubmit = () => {
				// Handle form submition
				submitForm(editPage);
	
				// Prevent default behavior
				return false;
			};
		}
	}
};


/**
 * Copy code
 */
const copyCode = () => {
	const selector = document.querySelectorAll('.copy-code');
	if (selector.length > 0) {
		selector.forEach(node => {
			node.onclick = () => {
				// Fetch code
				const code = node.dataset.code;

				// Copy code to clipboard
				polaris.copy(code).then(result => {
					// Alert the user
					if (result) {
						polaris.alert('Code copied to clipboard!', "fadeInTop", "fadeOutBottom", "success", "", true, 750, 0);
					}
				});

				// Prevent default behavior
				return false;
			};
		});
	}
};


/**
 * Add & Edit plugin
 */
const addEditPlugin = () => {
	const add  = document.querySelector('#add-plugin');
	const edit = document.querySelector('#edit-plugin');

	// Check forms
	if (add || edit) {
		const cat		 = document.querySelector('#plugin-cat');
		const type		 = document.querySelector('#plugin-type');
		const types		 = type.querySelectorAll('optgroup');
		const devOptions = document.querySelector(".developer-options");
		let options      = document.querySelector('#plugin-options');
		let settings     = document.querySelectorAll('#plugin-settings .field-option');

		/**
		 * plugin settings inputs
		 */
		const pluginSettings = () => {
			settings = document.querySelectorAll('#plugin-settings .field-option');
			options  = document.querySelector('#plugin-options');

			// Plugin settings
			settings.forEach(field => {
				field.onchange = function() {
					let str, reg, rep, name, val;

					str  = JSON.stringify(JSON.parse(document.querySelector('#plugin-options').value), null, 4);
					name = this.name;
					val  = this.value;

					// Check field type
					if (this.type === 'checkbox') {
						if (this.checked) {
							reg = new RegExp('"' + name + '": false');
							rep = `"${name}": true`;
						}
						else {
							reg = new RegExp('"' + name + '": true');
							rep = `"${name}": false`;
						}
					}
					else if (this.type === 'number') {
						if (!val) val = 0;
						reg = new RegExp('"' + name + '": .[0-9]*');
						rep = `"${name}": ${val}`;
					}
					else {
						// reg = /Example":\s*".*"/;
						reg = new RegExp('"' + name + '": ".*"');
						rep = `"${name}": "${val}"`;
					}

					// Update options json object
					options.value = str.replace(reg, rep);
				};
			});
		};

		// Plugin Category
		cat.onchange = function() {
			types.forEach(elem => {
				if (elem.dataset.type == this.value) {
					elem.removeAttribute('disabled');
					elem.classList.remove('display-none');
					type.value = elem.querySelectorAll('option')[0].value;
					type.onchange();
				}
				else {
					elem.setAttribute('disabled', true);
					elem.classList.add('display-none');
				}
			});
		};

		// Plugin type
		type.onchange = function() {
			// Fetch pugin source
			fetch(this.dataset.action, {
				method: this.dataset.method,
				headers: new Headers({
					'Content-Type': 'application/json'
				}),
				body: JSON.stringify({pluginType: this.value})
			})
			.then(response => response.json())
			.then(result => {
				// On success
				if (result.status === "success") {
					options.value = result.options;
					document.querySelector('#plugin-settings').innerHTML = result.settings;

					// Plugin settings
					pluginSettings();
				}
			});
		};

		// Plugin settings
		pluginSettings();

		// Developer options
		devOptions.onclick = () => {
			options     = document.querySelector('#plugin-options');
			const title = 'Plugin Developer Settings';
			let body;

			// Check language direction
			if (dataDir === 'ltr') {
				body  = `
					<div class="popup__control mb-md">
						<a href="#" class="px-xs">Reset?</a>
						<div class="popup popup-right popup-lg popup-click text-center" id="options-popup">
							<button type="button" class="btn btn-success btn-xs mb-sm mt-sm popup--close mr-xs" style="min-width: 40px;">NO</button>
							<button type="button" class="btn btn-danger btn-xs mb-sm mt-sm ml-xs" id="reset-options" style="min-width: 40px;">YES</button>
						</div>
					</div>
					<textarea class="field field-focus scroll scroll-sm scroll-round font-mono font-lg" id="save-options" rows="9">${options.value}</textarea>
				`;
			}
			else {
				body  = `
				<div class="popup__control mb-md">
					<a href="#" class="px-xs">Reset?</a>
					<div class="popup popup-left popup-lg popup-click text-center" id="options-popup">
						<button type="button" class="btn btn-success btn-xs mb-sm mt-sm popup--close ml-xs" style="min-width: 40px;">NO</button>
						<button type="button" class="btn btn-danger btn-xs mb-sm mt-sm mr-xs" id="reset-options" style="min-width: 40px;">YES</button>
					</div>
				</div>
				<textarea class="field field-focus scroll scroll-sm scroll-round font-mono font-lg" id="save-options" rows="9">${options.value}</textarea>
				`;
			}

			// Open up modal
			polaris.modal(title, body, "", "sm", "zoomIn", "zoomOut", "", true, true, 500);

			// Initialize polaris
			polaris.init();

			// Save partial
			const reset = document.querySelector('#reset-options');
			const save  = document.querySelector('#save-options');
			const popup = document.querySelector('#options-popup');

			// Save options
			save.onchange = () => {
				options.value = save.value;
			};

			// Reset options
			reset.onclick = function() {
				const source  = document.querySelector('#plugin-type').value;
				const btnText = this.innerHTML;
	
				// Change button text
				this.innerHTML = "Requesting...";

				// Fetch pugin source
				fetch(devOptions.dataset.action, {
					method: devOptions.dataset.method,
					headers: new Headers({
						'Content-Type': 'application/json'
					}),
					body: JSON.stringify({resetPlugin: source, url: devOptions.dataset.url, id: devOptions.dataset.id})
				})
				.then(response => response.json())
				.then(result => {
					// On success
					if (result.status === "success") {
						save.value = result.options;
						save.onchange();

						// Reset settings
						document.querySelector('#plugin-settings').innerHTML = result.settings;

						// Plugin settings
						pluginSettings();

						// Alert user
						polaris.alert('Settings reseted successfully!', "fadeInTop", "fadeOutBottom", "success", "", true, 750, 0);
		
						// Reset button
						this.innerHTML = btnText;
		
						// Close popup
						popup.classList.remove("popup-open");
					}
				});
			};
		};
		
		// Add submit
		if (add) {
			add.onsubmit = () => {
				// Handle form submition
				submitForm(add)
				.then((result) => {
					// On success
					if (result.status) {
						// Update the dynamic link
						let link = document.querySelector("#dynamic-link");
						link.setAttribute('href', result.data.next);
	
						// Trigger the click event
						link.click();
					}
				});
	
				// Prevent default behavior
				return false;
			};
		}

		if (edit) {
			// Edit submit
			edit.onsubmit = () => {
				// Handle form submition
				submitForm(edit);

				// Prevent default behavior
				return false;
			};
		}
	}
};


/**
 * Delete plugin
 */
const deletePlugin = () => {
	if (document.querySelector("#manage-plugins")) {
		let parent = document.querySelector("#manage-plugins");
		let selectors = parent.querySelectorAll(".delete-plugin");

		if (selectors.length > 0) {
			selectors.forEach((selector) => {
				selector.onclick = () => {
					let id = selector.getAttribute("data-id");
					let tr = parent.querySelector(`tr[data-id='${id}']`);

					// Start the delete process
					fetchAPI(parent, selector, {delete_plugin: true, id: id})
					.then((result) => {
						// On success
						if (result.status) {
							// Remove the plugin from the table
							polaris.remove(tr);
	
							// Find trs length
							let trs = document.querySelectorAll("#manage-plugins tr");
	
							// Check trs length
							if (trs.length == 1) {
								let trParent = document.querySelector("#manage-plugins tbody");
	
								// Append tr
								polaris.append('tr', trParent, '<td class="text-center font-500" colspan="5">No plugin found!</td>');
							}
						}
					});
				}
			});
		}
	}
};



/**
 * Admin charts
 */
const adminCharts = () => {
	// Visits & Users Chart
	const visitsUsersChart = document.querySelector('#visits-users-chart');
	if (visitsUsersChart) {
        let doc    = document.querySelector('.doc');
        let action = visitsUsersChart.dataset.action;

		// Visits & Users API
		fetch(action, {
			method: 'put',
			headers: new Headers({
				'Content-Type': 'application/json'
			}),
			body: JSON.stringify({
				visits_users: true
			})
		})
        .then(response => response.json())
        .then(result => {
			// Check result
			if (result.status == 'success') {
				let labels = eval(result.labels);
				let users  = eval(result.users);
				let visits = eval(result.visits);

                // Instantiate the Chart class
				const chart = new Chart(visitsUsersChart.getContext('2d'), {
					type:    'bar',     // line, bar, pie, doughnut, etc'
					data:    {
						labels:   labels,		 				// ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
						datasets: [
							{
								label:           'Users',
								data:            users,			// [12, 14, 8, 22, 21, 7, 15]
								backgroundColor: 'rgba(242, 56, 56, 0.87)',
								borderColor:     'rgba(242, 56, 56, 0.5)'
							},
							{
								label:           'Visits',
								data:            visits,		// [84, 97, 63, 134, 119, 73, 108]
								backgroundColor: 'rgba(66, 131, 242, 0.87)',
								borderColor:     'rgba(66, 131, 242, 0.5)'
							}
						]
					},
					options: {
						scales: {
							x: {
								grid: {
									color: () => (doc.classList.contains('doc-light')) ? '#DBDBDB' : '#595959',
								},
								ticks: {
									color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
								}
							},
							y: {
								grid: {
									color: () => (doc.classList.contains('doc-light')) ? '#DBDBDB' : '#595959',
								},
								ticks: {
									color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
								}
							}
						},
						plugins: {
							legend: {
								labels: {
									color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
								}
							},
							title: {
								display: true,
								text:    'Visits & Users',
								color: () => (doc.classList.contains('doc-light')) ? '#595959' : '#C1C1C1',
								font: {
									size:   16,
									weight: 300
								}
							}
						}
					}
				});
			}
		});
	}

	// Visits & Countries Chart
	const visitsCountriesChart = document.querySelector('#visits-countries-chart');
	if (visitsCountriesChart) {
        let doc = document.querySelector('.doc');
        let action = visitsUsersChart.dataset.action;

		// Visits & Users API
		fetch(action, {
			method: 'put',
			headers: new Headers({
				'Content-Type': 'application/json'
			}),
			body: JSON.stringify({
				visits_countries: true
			})
		})
        .then(response => response.json())
        .then(result => {
			// Check result
			if (result.status == 'success') {
				let labels = eval(result.labels);
				let visits = eval(result.visits);
		
                // Instantiate the Chart class
				const chart = new Chart(visitsCountriesChart.getContext('2d'), {
					type:    'pie',     // line, bar, pie, doughnut, etc'
					data:    {
						labels:   labels,						// ['Canada', 'America', 'Germany', 'Japan', 'England', 'Italy']
						datasets: [
							{
								label:           'Visits',
								data:            visits,		// [33, 24, 15, 12, 9, 1]
								backgroundColor: [
									'rgba(66, 131, 242, 0.87)',
									'rgba(242, 56, 56, 0.87)',
									'rgba(57, 182, 99, 0.87)',
									'rgba(61, 183, 39, 0.87)',
									'rgba(255, 192, 2, 0.87)',
									'rgba(252, 225, 22, 0.87)'
								],
								borderColor: () => (doc.classList.contains('doc-light')) ? '#FFFFFF' : '#242424',
								borderWidth: 2
							}
						]
					},
					options: {
						plugins: {
							legend: {
								labels: {
									color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
								}
							},
							title: {
								display: true,
								text:    'Visits & Countries',
								color: () => (doc.classList.contains('doc-light')) ? '#595959' : '#C1C1C1',
								font: {
									size:   16,
									weight: 300
								}
							}
						}
					}
				});
			}
		});
	}
};


/**
 * Upload slideshow
 */
const uploadSlideshow = () => {
	const selector = document.querySelector('#upload-slide-data');
	if (selector) {
		// Slides
		const slides = document.querySelector('#slideshow-slides');

		// Form data
		const form     = document.querySelector('#upload-slide-form');
		const fileMain = form.querySelector('.file-main');

		// Upload data
		const fileBtn    = selector.querySelector('.file-btn');
		const fileInput  = selector.querySelector('.file-input');
		const fileUpload = selector.querySelector('.file-upload');

		let files = [];
		let names = [];
		let exts  = [];
		let sizes = [];
		
		const uploadResult = selector.querySelector('#upload-result');

		let content = '';
	
		// Select Button
		fileBtn.onclick = () => {
			// Trigger file input click
			fileMain.click();
		};

		// Select input
		fileInput.onclick = () => {
			// Trigger file input click
			fileMain.click();
		};

		// File input onchange
		fileMain.onchange = () => {
			files = fileMain.files;
			names = [];
			exts  = [];
			sizes = [];

			// Find files name, size, and extension
			for (let i = 0; i < files.length; i++) {
				names[i] = files[i].name; 
				sizes[i] = files[i].size; 
				exts[i]  = files[i].name.split('.')[files.length - 1];
			}

			// Update select input
			fileInput.value = names.join(', ');
		};

		// Upload buttom
		fileUpload.onclick = () => {
			let uploadError      = false;
			const uploadStorage  = window.localStorage;
			const uploadSettings = JSON.parse(uploadStorage.getItem("upload-settings"));

			// Reset the upload result
			uploadResult.innerHTML = '';

			// No file chosen
			if (files.length == 0) {
				// Alert result message
				polaris.alert('No file selected!', "fadeInTop", "fadeOutBottom", 'warning', "", true, 750, 0);
			}
			// File chosen
			else {
				let fileName, fileSize, nameArr, fileExt;

				// Loop files
				for (let i = 0; i < files.length; i++) {
					fileName = files[i].name; 
					fileSize = files[i].size; 
					nameArr  = fileName.split('.');
					fileExt  = '.' + nameArr[nameArr.length - 1];

					// File extension error
					if (!uploadSettings['image_types'].includes(fileExt) && !uploadSettings['video_types'].includes(fileExt)) {
						content  = `<p class="mb-sm font-lg color-danger">${fileName} (Extension error!)</p>`;
						polaris.append('div', uploadResult, content);

						uploadError = true;
					}
					// Correct extension
					else {
						// Image
						if (uploadSettings['image_types'].includes(fileExt)) {
							// File size error
							if (fileSize > uploadSettings['image_size']) {
								content  = `<p class="mb-sm font-lg color-danger">${fileName} (Size error!)</p>`;
								polaris.append('div', uploadResult, content);
								uploadError = true;
							}
						}
						// Video
						else if (uploadSettings['video_types'].includes(fileExt)) {
							// File size error
							if (fileSize > uploadSettings['video_size']) {
								content  = `<p class="mb-sm font-lg color-danger">${fileName} (Size error!)</p>`;
								polaris.append('div', uploadResult, content);
								uploadError = true;
							}
						}
					}
				}

				// Files are OK
				if (!uploadError) {
					// Submit the form
					form.querySelector('.submit').click();
				}
			}

			// Prevent default behavior
			return false;
		};

		// Form API
		form.onsubmit = () => {
			// Reset the upload result
			uploadResult.innerHTML = '';

			// Disabled upload button
			fileUpload.setAttribute('disabled', true);

			// Append the progressbar
			content = `
				<div class="progress mb-md" style="height: 1rem;">
					<div class="progress--bar bg-notice color-white" style="width: 0%; transition: width 0.5s ease;">0%</div>
				</div>
			`;
			polaris.append('div', uploadResult, content);

			// Progress data
			const progressBar = uploadResult.querySelector(`.progress--bar`);
			let   progress    = 0;

			// XMLHttp object
			const xhr = new XMLHttpRequest();

			// On ready state change
			xhr.onreadystatechange = function () {
				this.upload.onprogress = e => {
					progress = Math.floor((e.loaded / e.total) * 100);
					
					progressBar.style.width = `${progress}%`;
					progressBar.innerHTML   = `${progress}%`;
				};
			};

			// On load
			xhr.onload = function() {
				let result = JSON.parse(this.responseText);

				// Alert result message
				polaris.alert(result.message, "fadeInTop", "fadeOutBottom", result.status, "", true, 750, 0);

				// Success
				if (result.status == 'success') {
					polaris.toggleClass(progressBar, 'bg-notice', 'bg-success');
					progressBar.style.width = '100%';
					progressBar.innerHTML   = '100%';

					// Update slides
					slides.innerHTML = result.slides;
				
					// Initialize polaris
					polaris.init();
	
					// Delete handler
					deleteSlideshow();

					// Edit handler
					editSlideshow();

				// Error
				} else {
					polaris.toggleClass(progressBar, 'bg-notice', 'bg-danger');
					progressBar.style.width = '100%';
					progressBar.innerHTML   = '100%';
				}

				// Enable upload button
				fileUpload.removeAttribute('disabled');

				// Reset main input
				fileMain.value = "";
			
				// Reset select input
				fileInput.value = 'No file chosen!';
			};

			// Update form data
			let data   = new FormData(form);
			let method = form.getAttribute('method');
			let action = form.getAttribute('action');

			// Start upload process
			xhr.open(method, action, true);
			xhr.setRequestHeader("Accept", "application/json");
			xhr.setRequestHeader("enctype", "multipart/form-data");
			xhr.send(data);
			
			// Prevent default behavior
			return false;
		};

	}
};


/**
 * Create slideshow (custom)
 */
const createSlideshow = () => {
	const selector = document.querySelector('#create-slide-data');
	if (selector) {
		// Slides
		const slides = document.querySelector('#slideshow-slides');

		// Form data
		const form = document.querySelector('#create-slide-form');

		// Form API
		form.onsubmit = () => {
			// Handle form submition
			submitForm(form)
			.then(result => {
				if (result.status) {
					// Reset form
					form.reset();

					// Reset summernote
					$('.summernote-mini').summernote('reset');
	
					// Update slides
					slides.innerHTML = result.data.slides;
				
					// Initialize polaris
					polaris.init();

					// Delete handler
					deleteSlideshow();

					// Edit handler
					editSlideshow();
				}
			});

			// Prevent default behavior
			return false;
		};
	}
};


/**
 * Delete slideshow
 */
const deleteSlideshow = () => {
	const parent = document.querySelector("#slideshow-slides");

	if (parent) {
		const selectors = parent.querySelectorAll(".delete-slide");

		if (selectors.length > 0) {
			selectors.forEach((selector) => {
				selector.onclick = () => {
					let id    = selector.getAttribute("data-id");
					let slide = parent.querySelector(`.img__frame[data-id='${id}']`);

					// Update parent method
					parent.dataset.method = "delete";

					// Start the delete process
					fetchAPI(parent, selector, {delete_slideshow: id})
					.then((result) => {
						// On success
						if (result.status) {
							// Close the popup
							parent.querySelector(`.popup[data-id='${id}']`).classList.remove("popup-open");

							// Remove the slide from the page
							polaris.remove(slide);
	
							// Find slides length
							let slides = parent.querySelectorAll(".img__frame");
	
							// Check slides length
							if (slides.length == 0) {
								parent.innerHTML = 'No slide found!';
							}

							// Reset parent method
							parent.dataset.method = ""
						}
					});
				}
			});
		}
	}	
};


/**
 * Edit slideshow
 */
const editSlideshow = () => {
	const parent = document.querySelector("#slideshow-slides");

	// Check container
	if (parent) {
		
		// Slide media
		if (parent.querySelectorAll(".edit-media").length > 0) {
			const selectors = parent.querySelectorAll(".edit-media");

			selectors.forEach((selector) => {
				selector.onclick = () => {
					const id 		= selector.getAttribute("data-id");
					const container = parent.querySelector(`.img__frame[data-id='${id}']`);

					// Upload settings
					const uploadStorage  = window.localStorage;
					const uploadSettings = JSON.parse(uploadStorage.getItem("upload-settings"));

					// Find the media & types
					let media, types;
					if (container.querySelector('img')) {
						media = container.querySelector('img');
						types = uploadSettings['image_types'];
					}
					else if (container.querySelector('source')) {
						media = container.querySelector('source');
						types = uploadSettings['video_types'];
					}

					const view = container.querySelector('.view-media');

					// Produce modal content
					const title  = `Edit Slideshow Media`;
					const form   = document.querySelector('#dynamic-form');
					const method = form.dataset.method;
					const action = form.dataset.action;
					const body   = `
					<form action="${action}" method="${method}" enctype="multipart/form-data">
						${form.innerHTML}
					</form>
					`;

					// Create modal
					polaris.modal(title, body, "", "xs", "zoomIn", "zoomOut", "", true, true, 500);

					// Final form
					const finalForm = document.querySelector('.modal').querySelector('form');

					// Update form
					finalForm.querySelector('input[name=form-type]').value = 'update-slideshow-media';
					finalForm.querySelector('input[name=id]').value = id;
					finalForm.querySelector('.form-body').innerHTML = `
						<div class="display-none">
							<input type="file" class="file-main" name="file" accept="${types}" required>
						</div>
						<div class="group mb-md">
							<button type="button" class="btn btn-sm mb-min file-btn" style="min-width: 100px;"><i class="fa-solid fa-file-import"></i> Select</button>
							<input type="text" class="field field-sm mb-min file-input" value="No file chosen!" readonly>
							<button type="submit" class="btn btn-sm btn-app mb-min submit file-upload" style="min-width: 100px;"><i class="fa-solid fa-upload"></i> Upload</button>
						</div>
						<div class="upload-result"></div>
					`;

					// Form data
					const fileMain 	 = finalForm.querySelector('.file-main');
					const fileBtn    = finalForm.querySelector('.file-btn');
					const fileInput  = finalForm.querySelector('.file-input');
					const fileUpload = finalForm.querySelector('.file-upload');

					const uploadResult = finalForm.querySelector('.upload-result');
					let   content 	   = '';
					let   files		   = [];
					let   fname 	   = '';
					let   arr		   = [];
					let   ext  		   = '';
					let   size 		   = '';

					// Select Button
					fileBtn.onclick = () => {
						// Trigger file input click
						fileMain.click();
					};

					// Select input
					fileInput.onclick = () => {
						// Trigger file input click
						fileMain.click();
					};

					// File input onchange
					fileMain.onchange = () => {
						files = fileMain.files;
						
						// File chosen
						if (files.length > 0) {
							// Find files name, size, and extension
							fname = files[0].name;
							size  = files[0].size;
							arr   = fname.split('.');
							ext   = '.' + arr[arr.length - 1];

							// Update select input
							fileInput.value = fname;
						}
						// No file chosen
						else {
							// Update select input
							fileInput.value = 'No file chosen!';
						}
					};

					// Upload buttom
					fileUpload.onclick = () => {
						let uploadError = false;

						// Reset the upload result
						uploadResult.innerHTML = '';

						// No file chosen
						if (files.length == 0) {
							// Alert result message
							polaris.alert('No file selected!', "fadeInTop", "fadeOutBottom", 'warning', "", true, 750, 0);
							uploadError = true;
						}
						// File chosen
						else {
							// File extension error
							if (!uploadSettings['image_types'].includes(ext) && !uploadSettings['video_types'].includes(ext)) {
								content  = `<p class="mb-sm font-lg color-danger">${fname} (Extension error!)</p>`;
								polaris.append('div', uploadResult, content);

								uploadError = true;
							}
							// Correct extension
							else {
								// Image
								if (uploadSettings['image_types'].includes(ext)) {
									// File size error
									if (size > uploadSettings['image_size']) {
										content  = `<p class="mb-sm font-lg color-danger">${fname} (Size error!)</p>`;
										polaris.append('div', uploadResult, content);
										uploadError = true;
									}
								}
								// Video
								else if (uploadSettings['video_types'].includes(ext)) {
									// File size error
									if (size > uploadSettings['video_size']) {
										content  = `<p class="mb-sm font-lg color-danger">${fname} (Size error!)</p>`;
										polaris.append('div', uploadResult, content);
										uploadError = true;
									}
								}
							}
						}

						// Upload error
						if (uploadError) {
							// Prevent default behavior
							return false;
						}
					};

                    // Form submition
                    finalForm.onsubmit = () => {
						// Reset the upload result
						uploadResult.innerHTML = '';

						// Disabled upload button
						fileUpload.setAttribute('disabled', true);

						// Append the progressbar
						content = `
							<div class="progress mb-md" style="height: 1rem;">
								<div class="progress--bar bg-notice color-white" style="width: 0%; transition: width 0.5s ease;">0%</div>
							</div>
						`;
						polaris.append('div', uploadResult, content);

						// Progress data
						const progressBar = uploadResult.querySelector(`.progress--bar`);
						let   progress    = 0;

						// XMLHttp object
						const xhr = new XMLHttpRequest();

						// On ready state change
						xhr.onreadystatechange = function () {
							this.upload.onprogress = e => {
								progress = Math.floor((e.loaded / e.total) * 100);
								
								progressBar.style.width = `${progress}%`;
								progressBar.innerHTML   = `${progress}%`;
							};
						};

						// On load
						xhr.onload = function() {
							let result = JSON.parse(this.responseText);

							// Alert result message
							polaris.alert(result.message, "fadeInTop", "fadeOutBottom", result.status, "", true, 750, 0);

							// Success
							if (result.status == 'success') {
								polaris.toggleClass(progressBar, 'bg-notice', 'bg-success');
								progressBar.style.width = '100%';
								progressBar.innerHTML   = '100%';

								// Update slide media
								media.setAttribute('src', result.media);
								view.setAttribute('href', result.media);
							
							// Error
							} else {
								polaris.toggleClass(progressBar, 'bg-notice', 'bg-danger');
								progressBar.style.width = '100%';
								progressBar.innerHTML   = '100%';
							}

							// Enable upload button
							fileUpload.removeAttribute('disabled');

							// Reset main input
							fileMain.value = "";
						
							// Reset select input
							fileInput.value = 'No file chosen!';
						};

						// Update form data
						const data = new FormData(finalForm);

						// Start upload process
						xhr.open(method, action, true);
						xhr.setRequestHeader("Accept", "application/json");
						xhr.setRequestHeader("enctype", "multipart/form-data");
						xhr.send(data);

                        // Prevent default behavior
                        return false;
                    };
				}
			});
		}
		
		// Slide captions
		if (parent.querySelectorAll(".edit-captions").length > 0) {
			const selectors = parent.querySelectorAll(".edit-captions");

			selectors.forEach((selector) => {
				selector.onclick = () => {
					const id = selector.getAttribute("data-id");

					// Produce modal content
					const title  = 'Slideshow Captions';
					const body   = `
						<div class="fetch-output">Loading...</div>
					`;

					// Create modal
					polaris.modal(title, body, "", "md", "zoomIn", "zoomOut", "", true, true, 500);

					// Output container
					let output = document.querySelector('.modal').querySelector('.fetch-output');

					// Fetch captions' forms
					const form 	 = document.querySelector('#dynamic-form');
					const action = form.dataset.action;
					const method = form.dataset.method;
					fetchData(null, {slideCaptions: id, action: action}, action, method, null)
					.then((result) => {
						// On success
						if (result.status) {
							// Update output
							output.innerHTML = result.data.output;

							// Initialize polaris
							polaris.init();

							// Set summernote
							setSummernote(0);
							
							// Handle edit caption
							editSlideshowCaption();
							
							// Handle reset caption
							resetSlideshowCaption();
							
							// Handle delete caption
							deleteSlideshowCaption(action);

							// Add caption
							if (output.querySelector('.create-caption')) {
								const addSelector = output.querySelector('.create-caption');
								addSelector.onclick = () => {
									fetchData(addSelector, {addSlideCaptions: id}, action, 'post')
									.then((result) => {
										// On success
										if (result.status) {
											// Update caption forms
											// output.querySelector('.inner-output').innerHTML = 'Updating...<hr class="hr hr-g">';
											updateSlideshowCaptions(action, id, output.querySelector('.inner-output'));
										}
									});
								};
							}
						}
						// On error
						else {
							// Alert error message
							polaris.alert(result.data.message, "fadeInTop", "fadeOutBottom", result.data.status, "", true, 750, 0);
						}
					});
				}
			});
		}

		// Slide text
		if (parent.querySelectorAll(".edit-text").length > 0) {
			const selectors = parent.querySelectorAll(".edit-text");

			selectors.forEach((selector) => {
				selector.onclick = () => {
					const id 		= selector.getAttribute("data-id");
					const container = parent.querySelector(`.img__frame[data-id='${id}']`);
					const media 	= container.querySelector('.slide-text');
					const html      = container.querySelector('.slide-html');

					// Produce modal content
					const title  = 'Edit Slideshow Content';
					const form   = document.querySelector('#dynamic-form');
					const method = form.dataset.method;
					const action = form.dataset.action;
					const body   = `
					<form action="${action}" method="${method}" enctype="multipart/form-data">
						${form.innerHTML}
					</form>
					`;

					// Create modal
					polaris.modal(title, body, "", "sm", "zoomIn", "zoomOut", "", true, true, 500);

					// Final form
					const finalForm = document.querySelector('.modal').querySelector('form');

					// Update form
					let wysiwyg = 'summernote-mini';
					finalForm.querySelector('input[name=form-type]').value = 'edit-slideshow';
					finalForm.querySelector('input[name=id]').value = id;
					finalForm.querySelector('.form-body').innerHTML = `
						<div class="field__control mb-md">
							<textarea name="media" id="field-media" class="field field-focus scroll scroll-sm scroll-round ${wysiwyg}" rows="4">${html.innerHTML}</textarea>
						</div>
						<button type="submit" class="btn btn-app btn-border btn-focus submit mb-min">Submit</button>
					`;

					// Set Summernote
					setTimeout(() => {
						setSummernote();
					}, 250);

					// Form API
					finalForm.onsubmit = () => {
						// Handle form submition
						submitForm(finalForm)
						.then(result => {
							if (result.status) {
								// Update slide media * HTML
								media.innerHTML = result.data.media;
								html.innerHTML 	= result.data.html;
							}
						});

						// Prevent default behavior
						return false;
					};
				}
			});
		}

		// Sorting slideshow
		const container = document.querySelector('.draggable-grid');
		if (container) {
			container.onmousedown = () => {
				polaris.draggable(container).then(() => {
					// Fetch required data
					const form 	 = document.querySelector('#dynamic-form');
					const action = form.dataset.action;
					const method = form.dataset.method;

					const draggables = Array.from(container.children);
					let   data 		 = {};

					draggables.forEach(draggable => {
						data[draggable.dataset.id] = Number(draggable.dataset.order);
					});

					// Perform the sorting process
					fetchData(null, {sortSlideshow: data}, action, method, null);
				});
			};
		}

	}	
};


/**
 * Update slide captions
 */
const updateSlideshowCaptions = (action, slide_id, output) => {
	fetchData(null, {slideCaptionForms: slide_id, action: action}, action, 'put', null)
	.then((result) => {
		// On success
		if (result.status) {
			// Update output
			output.innerHTML = result.data.output;

			// Initialize polaris
			polaris.init();

			// Set summernote
			setSummernote(0);
							
			// Handle edit caption
			editSlideshowCaption();
			
			// Handle reset caption
			resetSlideshowCaption();
			
			// Handle delete caption
			deleteSlideshowCaption(action);
		}
	});
};


/**
 *  Edit slide caption
 */
const editSlideshowCaption = () => {
	const parent = document.querySelector('#slideshow-caption-container');

	if (parent) {
		const forms = document.querySelectorAll('.edit-caption');

		if (forms.length > 0) {
			forms.forEach((form) => {
				form.onsubmit = () => {
					// Handle form submition
					submitForm(form);
		
					// Prevent default behavior
					return false;
				};
			});
		}
	}

};


/**
 *  Handle reset caption (developer options)
 */
const resetSlideshowCaption = () => {
	const parent = document.querySelector('#slideshow-caption-container');

	if (parent) {
		const selectors = parent.querySelectorAll('.reset-options');
	
		if (selectors.length > 0) {
			selectors.forEach((selector) => {
				// Reset options
				selector.onclick = function() {
					const btnText = this.innerHTML;
					const id 	  = this.dataset.id;
					const popup   = parent.querySelector(`#options-popup-${id}`);
					const options = parent.querySelector(`#options-${id}`);
		
					// Change button text
					this.innerHTML = "Requesting...";
	
					// Fetch caption option
					polaris.text(`/statics/json/slideshow-captions.json`)
					.then((result) => {
						options.value = result;
	
						// Alert user
						polaris.alert('Settings reseted successfully!', "fadeInTop", "fadeOutBottom", "success", "", true, 750, 0);
		
						// Reset button
						this.innerHTML = btnText;
		
						// Close popup
						popup.classList.remove("popup-open");
					});
				};
			});
		}
	}
};


/**
 *  Handle delete caption
 */
const deleteSlideshowCaption = (action) => {
	const parent = document.querySelector('#slideshow-caption-container');

	if (parent) {
		const selectors = parent.querySelectorAll('.delete-caption');
	
		if (selectors.length > 0) {
			selectors.forEach((selector) => {
				// Delete caption
				selector.onclick = function() {
					const id 	= this.dataset.id;
					const popup = parent.querySelector(`#delete-popup-${id}`);
					const form  = parent.querySelector(`#caption-form-${id}`);
		
					// Start the delete process
					fetchData(selector, {delete_slideshow_caption: id}, action, 'delete')
					.then((result) => {
						// On success
						if (result.status) {
							// Close popup
							popup.classList.remove("popup-open");

							// Remove the caption form
							polaris.remove(form);
	
							// Find captions length
							const captions = document.querySelectorAll('.edit-caption');
	
							// Check captions length
							if (captions.length == 0) {
								parent.innerHTML = 'No caption found!<hr class="hr hr-g">';
							}
						}
					});
				};
			});
		}
	}
};


/**
 * Upload splide
 */
const uploadSplide = () => {
	const selector = document.querySelector('#upload-splide-data');
	if (selector) {
		// Slides
		const slides = document.querySelector('#splide-slides');

		// Form data
		const form     = document.querySelector('#upload-splide-form');
		const fileMain = form.querySelector('.file-main');

		// Upload data
		const fileBtn    = selector.querySelector('.file-btn');
		const fileInput  = selector.querySelector('.file-input');
		const fileUpload = selector.querySelector('.file-upload');

		let files = [];
		let names = [];
		let exts  = [];
		let sizes = [];
		
		const uploadResult = selector.querySelector('#upload-result');

		let content = '';
	
		// Select Button
		fileBtn.onclick = () => {
			// Trigger file input click
			fileMain.click();
		};

		// Select input
		fileInput.onclick = () => {
			// Trigger file input click
			fileMain.click();
		};

		// File input onchange
		fileMain.onchange = () => {
			files = fileMain.files;
			names = [];
			exts  = [];
			sizes = [];

			// Find files name, size, and extension
			for (let i = 0; i < files.length; i++) {
				names[i] = files[i].name; 
				sizes[i] = files[i].size; 
				exts[i]  = files[i].name.split('.')[files.length - 1];
			}

			// Update select input
			fileInput.value = names.join(', ');
		};

		// Upload buttom
		fileUpload.onclick = () => {
			let uploadError      = false;
			const uploadStorage  = window.localStorage;
			const uploadSettings = JSON.parse(uploadStorage.getItem("upload-settings"));

			// Reset the upload result
			uploadResult.innerHTML = '';

			// No file chosen
			if (files.length == 0) {
				// Alert result message
				polaris.alert('No file selected!', "fadeInTop", "fadeOutBottom", 'warning', "", true, 750, 0);
			}
			// File chosen
			else {
				let fileName, fileSize, nameArr, fileExt;

				// Loop files
				for (let i = 0; i < files.length; i++) {
					fileName = files[i].name; 
					fileSize = files[i].size; 
					nameArr  = fileName.split('.');
					fileExt  = '.' + nameArr[nameArr.length - 1];

					// File extension error
					if (!uploadSettings['image_types'].includes(fileExt)) {
						content  = `<p class="mb-sm font-lg color-danger">${fileName} (Extension error!)</p>`;
						polaris.append('div', uploadResult, content);

						uploadError = true;
					}
					// File size error
					else if (fileSize > uploadSettings['image_size']) {
						content  = `<p class="mb-sm font-lg color-danger">${fileName} (Size error!)</p>`;
						polaris.append('div', uploadResult, content);
						uploadError = true;
					}
				}

				// Files are OK
				if (!uploadError) {
					// Submit the form
					form.querySelector('.submit').click();
				}
			}

			// Prevent default behavior
			return false;
		};

		// Form API
		form.onsubmit = () => {
			// Reset the upload result
			uploadResult.innerHTML = '';

			// Disabled upload button
			fileUpload.setAttribute('disabled', true);

			// Append the progressbar
			content = `
				<div class="progress mb-md" style="height: 1rem;">
					<div class="progress--bar bg-notice color-white" style="width: 0%; transition: width 0.5s ease;">0%</div>
				</div>
			`;
			polaris.append('div', uploadResult, content);

			// Progress data
			const progressBar = uploadResult.querySelector(`.progress--bar`);
			let   progress    = 0;

			// XMLHttp object
			const xhr = new XMLHttpRequest();

			// On ready state change
			xhr.onreadystatechange = function () {
				this.upload.onprogress = e => {
					progress = Math.floor((e.loaded / e.total) * 100);
					
					progressBar.style.width = `${progress}%`;
					progressBar.innerHTML   = `${progress}%`;
				};
			};

			// On load
			xhr.onload = function() {
				let result = JSON.parse(this.responseText);

				// Alert result message
				polaris.alert(result.message, "fadeInTop", "fadeOutBottom", result.status, "", true, 750, 0);

				// Success
				if (result.status == 'success') {
					polaris.toggleClass(progressBar, 'bg-notice', 'bg-success');
					progressBar.style.width = '100%';
					progressBar.innerHTML   = '100%';

					// Update slides
					slides.innerHTML = result.slides;
				
					// Initialize polaris
					polaris.init();
	
					// Delete handler
					deleteSplide();

					// Edit handler
					editSplide();

				// Error
				} else {
					polaris.toggleClass(progressBar, 'bg-notice', 'bg-danger');
					progressBar.style.width = '100%';
					progressBar.innerHTML   = '100%';
				}

				// Enable upload button
				fileUpload.removeAttribute('disabled');

				// Reset main input
				fileMain.value = "";
			
				// Reset select input
				fileInput.value = 'No file chosen!';
			};

			// Update form data
			let data   = new FormData(form);
			let method = form.getAttribute('method');
			let action = form.getAttribute('action');

			// Start upload process
			xhr.open(method, action, true);
			xhr.setRequestHeader("Accept", "application/json");
			xhr.setRequestHeader("enctype", "multipart/form-data");
			xhr.send(data);
			
			// Prevent default behavior
			return false;
		};

	}
};


/**
 * Create splide (custom)
 */
const createSplide = () => {
	const selector = document.querySelector('#create-splide-data');
	if (selector) {
		// Slides
		const slides = document.querySelector('#splide-slides');

		// Form data
		const form = document.querySelector('#create-splide-form');

		// Form API
		form.onsubmit = () => {
			// Handle form submition
			submitForm(form)
			.then(result => {
				if (result.status) {
					// Reset form
					form.reset();

					// Reset summernote
					$('.summernote-mini').summernote('reset');
	
					// Update slides
					slides.innerHTML = result.data.slides;
				
					// Initialize polaris
					polaris.init();

					// Delete handler
					deleteSplide();

					// Edit handler
					editSplide();
				}
			});

			// Prevent default behavior
			return false;
		};
	}
};


/**
 * Delete splide
 */
const deleteSplide = () => {
	const parent = document.querySelector("#splide-slides");

	if (parent) {
		const selectors = parent.querySelectorAll(".delete-slide");

		if (selectors.length > 0) {
			selectors.forEach((selector) => {
				selector.onclick = () => {
					let id    = selector.getAttribute("data-id");
					let slide = parent.querySelector(`.img__frame[data-id='${id}']`);

					// Update parent method
					parent.dataset.method = "delete";

					// Start the delete process
					fetchAPI(parent, selector, {delete_splide: id})
					.then((result) => {
						// On success
						if (result.status) {
							// Close the popup
							parent.querySelector(`.popup[data-id='${id}']`).classList.remove("popup-open");

							// Remove the slide from the page
							polaris.remove(slide);
	
							// Find slides length
							let slides = parent.querySelectorAll(".img__frame");
	
							// Check slides length
							if (slides.length == 0) {
								parent.innerHTML = 'No slide found!';
							}

							// Reset parent method
							parent.dataset.method = ""
						}
					});
				}
			});
		}
	}	
};


/**
 * Edit splide image
 */
const editSplide = () => {
	const parent = document.querySelector("#splide-slides");

	// Check container
	if (parent) {
		// Edit media
		if (parent.querySelectorAll(".edit-media").length > 0) {
			const selectors = parent.querySelectorAll(".edit-media");

			selectors.forEach((selector) => {
				selector.onclick = () => {
					const id    	= selector.getAttribute("data-id");
					const container = parent.querySelector(`.img__frame[data-id='${id}']`);
					const image 	= container.querySelector('img');
					const view  	= container.querySelector('.view-media');

					const uploadStorage  = window.localStorage;
					const uploadSettings = JSON.parse(uploadStorage.getItem("upload-settings"));

					// Produce modal content
					const title  = `Edit Splide Image`;
					const form   = document.querySelector('#dynamic-form');
					const method = form.dataset.method;
					const action = form.dataset.action;
					const body   = `
					<form action="${action}" method="${method}" enctype="multipart/form-data">
						${form.innerHTML}
					</form>
					`;

					// Create modal
					polaris.modal(title, body, "", "xs", "zoomIn", "zoomOut", "", true, true, 500);

					// Final form
					const finalForm = document.querySelector('.modal').querySelector('form');

					// Update form
					finalForm.querySelector('input[name=form-type]').value = 'update-splide-image';
					finalForm.querySelector('input[name=id]').value = id;
					finalForm.querySelector('.form-body').innerHTML = `
						<div class="display-none">
							<input type="file" class="file-main" name="file" accept="${uploadSettings['IMAGE_TYPES']}" required>
						</div>
						<div class="group mb-md">
							<button type="button" class="btn btn-sm mb-min file-btn" style="min-width: 100px;"><i class="fa-solid fa-file-import"></i> Select</button>
							<input type="text" class="field field-sm mb-min file-input" value="No file chosen!" readonly>
							<button type="submit" class="btn btn-sm btn-app mb-min submit file-upload" style="min-width: 100px;"><i class="fa-solid fa-upload"></i> Upload</button>
						</div>
						<div class="upload-result"></div>
					`;

					// Form data
					const fileMain 	 = finalForm.querySelector('.file-main');
					const fileBtn    = finalForm.querySelector('.file-btn');
					const fileInput  = finalForm.querySelector('.file-input');
					const fileUpload = finalForm.querySelector('.file-upload');

					const uploadResult = finalForm.querySelector('.upload-result');
					let   content 	   = '';
					let   files		   = [];
					let   fname 	   = '';
					let   arr		   = [];
					let   ext  		   = '';
					let   size 		   = '';

					// Select Button
					fileBtn.onclick = () => {
						// Trigger file input click
						fileMain.click();
					};

					// Select input
					fileInput.onclick = () => {
						// Trigger file input click
						fileMain.click();
					};

					// File input onchange
					fileMain.onchange = () => {
						files = fileMain.files;
						
						// File chosen
						if (files.length > 0) {
							// Find files name, size, and extension
							fname = files[0].name;
							size  = files[0].size;
							arr   = fname.split('.');
							ext   = '.' + arr[arr.length - 1];

							// Update select input
							fileInput.value = fname;
						}
						// No file chosen
						else {
							// Update select input
							fileInput.value = 'No file chosen!';
						}
					};

					// Upload buttom
					fileUpload.onclick = () => {
						let uploadError = false;

						// Reset the upload result
						uploadResult.innerHTML = '';

						// No file chosen
						if (files.length == 0) {
							// Alert result message
							polaris.alert('No file selected!', "fadeInTop", "fadeOutBottom", 'warning', "", true, 750, 0);
							uploadError = true;
						}
						// File chosen
						else {
							// File extension error
							if (!uploadSettings['image_types'].includes(ext)) {
								content  = `<p class="mb-sm font-lg color-danger">${fname} (Extension error!)</p>`;
								polaris.append('div', uploadResult, content);
								uploadError = true;
							}
							// File size error
							else if (size > uploadSettings['image_size']) {
								content  = `<p class="mb-sm font-lg color-danger">${fname} (Size error!)</p>`;
								polaris.append('div', uploadResult, content);
								uploadError = true;
							}
						}

						// Upload error
						if (uploadError) {
							// Prevent default behavior
							return false;
						}
					};

                    // Form submition
                    finalForm.onsubmit = () => {
						// Reset the upload result
						uploadResult.innerHTML = '';

						// Disabled upload button
						fileUpload.setAttribute('disabled', true);

						// Append the progressbar
						content = `
							<div class="progress mb-md" style="height: 1rem;">
								<div class="progress--bar bg-notice color-white" style="width: 0%; transition: width 0.5s ease;">0%</div>
							</div>
						`;
						polaris.append('div', uploadResult, content);

						// Progress data
						const progressBar = uploadResult.querySelector(`.progress--bar`);
						let   progress    = 0;

						// XMLHttp object
						const xhr = new XMLHttpRequest();

						// On ready state change
						xhr.onreadystatechange = function () {
							this.upload.onprogress = e => {
								progress = Math.floor((e.loaded / e.total) * 100);
								
								progressBar.style.width = `${progress}%`;
								progressBar.innerHTML   = `${progress}%`;
							};
						};

						// On load
						xhr.onload = function() {
							let result = JSON.parse(this.responseText);

							// Alert result message
							polaris.alert(result.message, "fadeInTop", "fadeOutBottom", result.status, "", true, 750, 0);

							// Success
							if (result.status == 'success') {
								polaris.toggleClass(progressBar, 'bg-notice', 'bg-success');
								progressBar.style.width = '100%';
								progressBar.innerHTML   = '100%';

								// Update slide image
								image.setAttribute('src', result.image);
								view.setAttribute('href', result.image);
							
							// Error
							} else {
								polaris.toggleClass(progressBar, 'bg-notice', 'bg-danger');
								progressBar.style.width = '100%';
								progressBar.innerHTML   = '100%';
							}

							// Enable upload button
							fileUpload.removeAttribute('disabled');

							// Reset main input
							fileMain.value = "";
						
							// Reset select input
							fileInput.value = 'No file chosen!';
						};

						// Update form data
						const data = new FormData(finalForm);

						// Start upload process
						xhr.open(method, action, true);
						xhr.setRequestHeader("Accept", "application/json");
						xhr.setRequestHeader("enctype", "multipart/form-data");
						xhr.send(data);

                        // Prevent default behavior
                        return false;
                    };
				}
			});
		}
		
		// Edit link
		if (parent.querySelectorAll(".edit-link").length > 0) {
			const selectors = parent.querySelectorAll(".edit-link");

			selectors.forEach((selector) => {
				selector.onclick = () => {
					const id     = selector.getAttribute("data-id");
					const link   = selector.dataset.link;
					const target = selector.dataset.target;
					const spa    = selector.dataset.spa;

					// Produce modal content
					const title  = `Edit Splide Link`;
					const form   = document.querySelector('#dynamic-form');
					const method = form.dataset.method;
					const action = form.dataset.action;
					const body   = `
					<form action="${action}" method="${method}">
						${form.innerHTML}
					</form>
					`;

					// Create modal
					polaris.modal(title, body, "", "xs", "zoomIn", "zoomOut", "", true, true, 500);

					// Final form
					const finalForm = document.querySelector('.modal').querySelector('form');

					// Update form
					finalForm.querySelector('input[name=form-type]').value = 'update-splide-link';
					finalForm.querySelector('input[name=id]').value = id;
					finalForm.querySelector('.form-body').innerHTML = `
						<div class="row gt-sm mb-md">
							<div class="col w-150px">
								<label for="field-link">*Link URL:</label>
							</div>
							<div class="col w-rest">
								<input type="text" name="link" class="field field-sm field-focus mb-min" id="field-link" value="${link}" required>
							</div>
						</div>
						<div class="row gt-sm mb-md">
							<div class="col w-150px">
								<label for="field-target">Link Target</label>
							</div>
							<div class="col w-rest">
								<select name="target" class="field field-sm field-focus mb-min" id="field-target">
									<option value="_self" ${ target == '_self' ? 'selected' : '' }>Self</option>
									<option value="_blank" ${ target == '_blank' ? 'selected' : '' }>Blank</option>
								</select>
							</div>
						</div>
						<div class="row gt-sm mb-md">
							<div class="col w-150px">
								<label for="field-spa">SPA Link:</label>
							</div>
							<div class="col w-rest">
								<div class="switch switch-slim switch-sm">
									<input type="checkbox" name="spa" id="field-spa" ${ spa == '1' ? 'checked' : '' }>
									<div class="switch--slider"></div>
								</div>
							</div>
						</div>
						<button type="submit" class="btn btn-app btn-border btn-focus submit mb-min">Submit</button>
					`;

                    // Form submition
                    finalForm.onsubmit = () => {
						// Handle form submition
						submitForm(finalForm)
						.then(result => {
							// Update the link datasets
							if (result.status) {
								selector.dataset.link = result.data.link;
								selector.dataset.target = result.data.target;
								selector.dataset.spa = result.data.spa;
							}
						});

                        // Prevent default behavior
                        return false;
                    };
				}
			});
		}
		
		// Edit description
		if (parent.querySelectorAll(".edit-description").length > 0) {
			const selectors = parent.querySelectorAll(".edit-description");

			selectors.forEach((selector) => {
				selector.onclick = () => {
					const id          = selector.getAttribute("data-id");
					const description = selector.dataset.description;
					const type    	  = selector.dataset.type;

					// Simple
					let title, wysiwyg, rows;
					if (type == 'SIMPLE') {
						title 	= `Edit Splide Description`;
						rows 	= '4';
					} 
					// Others
					else {
						title 	= 'Edit Splide Caption';
						rows 	= '1';
					}

					// Produce modal content
					const form   = document.querySelector('#dynamic-form');
					const method = form.dataset.method;
					const action = form.dataset.action;
					const body   = `
					<form action="${action}" method="${method}">
						${form.innerHTML}
					</form>
					`;

					// Create modal
					polaris.modal(title, body, "", "sm", "zoomIn", "zoomOut", "", true, true, 500);

					// Final form
					const finalForm = document.querySelector('.modal').querySelector('form');

					// Update form
					wysiwyg = 'summernote-mini';
					finalForm.querySelector('input[name=form-type]').value = 'update-splide-description';
					finalForm.querySelector('input[name=id]').value = id;
					finalForm.querySelector('.form-body').innerHTML = `
						<div class="field__control mb-md">
							<textarea name="description" class="field field-focus scroll scroll-sm scroll-round ${wysiwyg}" rows="${rows}">${description}</textarea>
						</div>
						<button type="submit" class="btn btn-app btn-border btn-focus submit mb-min">Submit</button>
					`;

					// Set Summernote
					setTimeout(() => {
						setSummernote();
					}, 250);

                    // Form submition
                    finalForm.onsubmit = () => {
						// Handle form submition
						submitForm(finalForm)
						.then(result => {
							// Update the link datasets
							if (result.status) {
								selector.dataset.description = result.data.description;
							}
						});

                        // Prevent default behavior
                        return false;
                    };
				}
			});
		}

		// Splide text
		if (parent.querySelectorAll(".edit-text").length > 0) {
			const selectors = parent.querySelectorAll(".edit-text");

			selectors.forEach((selector) => {
				selector.onclick = () => {
					const id 		= selector.getAttribute("data-id");
					const container = parent.querySelector(`.img__frame[data-id='${id}']`);
					const media 	= container.querySelector('.slide-text');
					const html      = container.querySelector('.slide-html');

					// Produce modal content
					const title  = 'Edit Splide Content';
					const form   = document.querySelector('#dynamic-form');
					const method = form.dataset.method;
					const action = form.dataset.action;
					const body   = `
					<form action="${action}" method="${method}" enctype="multipart/form-data">
						${form.innerHTML}
					</form>
					`;

					// Create modal
					polaris.modal(title, body, "", "sm", "zoomIn", "zoomOut", "", true, true, 500);

					// Final form
					const finalForm = document.querySelector('.modal').querySelector('form');

					// Update form
					let wysiwyg = 'summernote-mini';
					finalForm.querySelector('input[name=form-type]').value = 'edit-splide';
					finalForm.querySelector('input[name=id]').value = id;
					finalForm.querySelector('.form-body').innerHTML = `
						<div class="field__control mb-md">
							<textarea name="media" id="field-media" class="field field-focus scroll scroll-sm scroll-round ${wysiwyg}" rows="4">${html.innerHTML}</textarea>
						</div>
						<button type="submit" class="btn btn-app btn-border btn-focus submit mb-min">Submit</button>
					`;

					// Set Summernote
					setTimeout(() => {
						setSummernote();
					}, 250);

					// Form API
					finalForm.onsubmit = () => {
						// Handle form submition
						submitForm(finalForm)
						.then(result => {
							if (result.status) {
								// Update slide media * HTML
								media.innerHTML = result.data.media;
								html.innerHTML 	= result.data.html;
							}
						});

						// Prevent default behavior
						return false;
					};
				}
			});
		}

		// Sorting splide
		const container = document.querySelector('.draggable-grid');
		if (container) {
			container.onmousedown = () => {
				polaris.draggable(container).then(() => {
					// Fetch required data
					const form 	 = document.querySelector('#dynamic-form');
					const action = form.dataset.action;
					const method = form.dataset.method;

					const draggables = Array.from(container.children);
					let   data 		 = {};

					draggables.forEach(draggable => {
						data[draggable.dataset.id] = Number(draggable.dataset.order);
					});

					// Perform the sorting process
					fetchData(null, {sortSplide: data}, action, method, null);
				});
			};
		}

	}	
};


/**
 * Manage Menu plugin
 */
const manageMenu = () => {
	const parent = document.querySelector("#manage-menu");

	// Check container
	if (parent) {
		const output	  = parent.querySelector("#menu-output");
		let   dynamicForm = document.querySelector('#dynamic-form');
		const action      = dynamicForm.dataset.action;

		// Toggle mega menu
		if (parent.querySelector('#field-mega')) {
			const selector = parent.querySelector('#field-mega');
			const content  = parent.querySelector('#field-mega-content');

			selector.onchange = function() {
				if (this.checked) {
					content.classList.remove('display-none');
				} else {
					content.classList.add('display-none');
				}
			};
		}

		// Add menu
		if (parent.querySelector("#create-menu-form")) {
			const form = parent.querySelector("#create-menu-form");
			form.onsubmit = () => {
				// Handle form submition
				submitForm(form)
				.then((result) => {
					// On success
					if (result.status) {
						// Update the output
						output.innerHTML = result.data.output;

						// Reset the form
						form.reset();

						// Reset summernote
						if (document.querySelector('.summernote-mini')) {
							$('.summernote-mini').summernote('reset');
							parent.querySelector('#field-mega').onchange();
						}

						// Initialize polaris
						polaris.init();

						// Method recursion
						manageMenu();
					}
				});
	
				// Prevent default behavior
				return false;
			};
		}

		// Add submenu (Simple Menu & Custom Menu)
		if (parent.querySelectorAll(".add-submenu").length > 0) {
			const selectors = parent.querySelectorAll(".add-submenu");

			selectors.forEach((selector) => {
				selector.onclick = () => {
					const id 	= selector.getAttribute("data-id");
					dynamicForm = document.querySelector('#dynamic-form');

					// Produce modal content
					const title = 'Add Submenu';
					const body  = `
					<form action="${action}" method="post">
						${dynamicForm.innerHTML}
					</form>
					`;

					// Create modal
					polaris.modal(title, body, "", "sm", "zoomIn", "zoomOut", "", true, true, 500);

					// Final form
					const form = document.querySelector('.modal').querySelector('form');

					// Update form
					form.querySelector('input[name=form-type]').value = 'create-submenu';
					form.querySelector('input[name=id]').value = id;
					form.querySelector('.form-body').innerHTML = `
						<div class="row">
							<div class="col col-12 t-col-6">
								<div class="row gt-sm">
									<div class="col w-100 lt-w-150px">
										<label for="field-title">*Title</label>
									</div>
									<div class="col w-rest">
										<input type="text" name="title" id="field-title" class="field field-sm field-focus" required>
									</div>
								</div>
							</div>
							<div class="col w-100 t-col-6">
								<div class="row gt-sm">
									<div class="col w-100 lt-w-150px">
										<label for="field-link">*Link URL:</label>
									</div>
									<div class="col w-rest">
										<input type="text" name="link" class="field field-sm field-focus" id="field-link" value="#" required>
									</div>
								</div>
							</div>
							<div class="col col-12 t-col-6">
								<div class="row gt-sm">
									<div class="col w-100 lt-w-150px">
										<label for="field-target">Link Target</label>
									</div>
									<div class="col w-rest">
										<select name="target" class="field field-sm field-focus" id="field-target">
											<option value="_self" selected="">Self</option>
											<option value="_blank">Blank</option>
										</select>
									</div>
								</div>
							</div>
							<div class="col col-12 t-col-6 mb-md">
								<div class="row gt-sm">
									<div class="col w-100 lt-w-150px">
										<label for="field-spa">SPA Link:</label>
									</div>
									<div class="col w-rest">
										<div class="switch switch-slim switch-sm">
											<input type="checkbox" name="spa" id="field-spa">
											<div class="switch--slider"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<button type="submit" class="btn btn-sm btn-app btn-border btn-focus submit mb-min">Submit</button>
					`;

                    // Form submition
                    form.onsubmit = () => {
						// Handle form submition
						submitForm(form)
						.then(result => {
							// On success
							if (result.status) {
								// Update the output
								output.innerHTML = result.data.output;
		
								// Reset the form
								form.reset();

								// Initialize polaris
								polaris.init();

								// Method recursion
								manageMenu();
							}
						});

                        // Prevent default behavior
                        return false;
                    };
				}
			});
		}

		// Edit menu | submenu (Simple Menu & Custom Menu)
		if (parent.querySelectorAll(".edit-menu").length > 0) {
			const selectors = parent.querySelectorAll(".edit-menu");

			selectors.forEach((selector) => {
				selector.onclick = () => {
					const id 	= selector.getAttribute("data-id");
					const sub 	= selector.getAttribute("data-sub");
					dynamicForm = document.querySelector('#dynamic-form');

					// Produce modal content
					const title = Number(sub) ? 'Edit Submenu' : 'Edit Menu';
					const body  = `
					<form action="${action}" method="put">
						${dynamicForm.innerHTML}
					</form>
					`;

					// Create modal
					polaris.modal(title, body, "", "sm", "zoomIn", "zoomOut", "", true, true, 500);

					// Final form
					const form = document.querySelector('.modal').querySelector('form');

					// Update form
					form.querySelector('input[name=form-type]').value = 'edit-menu';
					form.querySelector('input[name=id]').value = id;
					form.querySelector('.form-body').innerHTML = 'Loading...';

					// Fetch the form body
					fetchData(null, {menuSource: id}, action, 'put', null)
					.then(result => {
						if (result.status) {
							// Update the form body
							form.querySelector('.form-body').innerHTML = result.data.source;

							// Check mega menu
							if (result.data.mega) {
								// Set summernote
								setSummernote(0);

								// Toggle mega menu (modal)
								if (form.querySelector('#field-mega-modal')) {
									const selectorModal = form.querySelector('#field-mega-modal');
									const contentModal  = form.querySelector('#field-mega-content-modal');

									selectorModal.onchange = function() {
										if (this.checked) {
											contentModal.classList.remove('display-none');
										} else {
											contentModal.classList.add('display-none');
										}
									};
								}
							}

							// Form submition
							form.onsubmit = () => {
								// Handle form submition
								submitForm(form)
								.then(result => {
									// On success
									if (result.status) {
										// Update the output
										output.innerHTML = result.data.output;
				
										// Initialize polaris
										polaris.init();
		
										// Method recursion
										manageMenu();
									}
								});
		
								// Prevent default behavior
								return false;
							};
						}
					});
				}
			});
		}

		// Delete menu
		if (parent.querySelectorAll('.delete-menu').length > 0) {
			const selectors = parent.querySelectorAll('.delete-menu');

			selectors.forEach((selector) => {
				// Delete menu
				selector.onclick = function() {
					const id 	= this.dataset.id;
					const popup = parent.querySelector(`#delete-popup-${id}`);
		
					// Start the delete process
					fetchData(selector, {delete_menu: id}, action, 'delete')
					.then((result) => {
						// On success
						if (result.status) {
							// Close popup
							popup.classList.remove("popup-open");

							// Update the output
							output.innerHTML = result.data.output;

							// Initialize polaris
							polaris.init();

							// Method recursion
							manageMenu();
						}
					});
				};
			});
		}

		// Sorting menu
		const containers = document.querySelectorAll('.draggable-list');
		if (containers.length) {
			containers.forEach(container => {
				container.onmousedown = () => {
					polaris.draggable(container).then(() => {
						// Fetch required data
						const form 	 = document.querySelector('#dynamic-form');
						const action = form.dataset.action;
						const method = form.dataset.method;
	
						const draggables = Array.from(container.children);
						let   data 		 = {};
	
						draggables.forEach(draggable => {
							data[draggable.dataset.id] = Number(draggable.dataset.order);
						});
	
						// Perform the sorting process
						fetchData(null, {sortMenu: data}, action, method, null);
					});
				};
            });
		}

	}	
};


/**
 * Load categories posts count
 */
const catPostsCount = () => {
	const table = document.querySelector('#blog-manage-category');

	if (table) {
		const action = table.dataset.action;
		const tbody  = table.querySelector('tbody');
		const trs    = tbody.querySelectorAll('tr:not(#no-item)');
		let ids      = [];

		// Fetch ids
		trs.forEach(tr => {
			ids.push(tr.dataset.id);
		});

		// Check ids
		if (ids.length > 0) {
			// Fetch posts count
			fetch(action, {
				method: 'put',
				headers: new Headers({
					'Content-Type': 'application/json'
				}),
				body: JSON.stringify({
					cat_post_count: true,
					ids:  	        ids
				})
			})
			.then(response => response.json())
			.then(result => {
				if (result.status == 'success') {
					// Update counters
					for (let i = 0; i < trs.length; i++) {
						trs[i].querySelector('.post-counter').innerHTML = result.counts[i];
					}
				}
			});
		}
	}
};


/**
 * Manage blog tags
 */
const blogTags = () => {
	const form = document.querySelector('#blog-tags');
	if (form) {
		const type = form.querySelector('input[name=form-type]');

		form.onsubmit = (result) => {
			// Handle form submition
			submitForm(form)
			.then(result => {
				// Update the form
				if (result.status && type.value == 'add-blog-tags') {
					form.setAttribute('method', 'put');
					type.value = 'edit-blog-tags';
				}
			});

			// Prevent default behavior
			return false;
		};
	}
};


/**
 * Add blog category
 */
const addBlogCat = () => {
	const form = document.querySelector('#add-blog-cat');
	
	if (form) {
		const table = document.querySelector('#blog-manage-category');
		const tbody = table.querySelector('tbody');

		form.onsubmit = (result) => {
			// Handle form submition
			submitForm(form)
			.then(result => {
				if (result.status) {
					// Reset form
					form.reset();

					// Check no item
					if (table.querySelector('#no-item')) {
						// Remove no item
						polaris.remove(table.querySelector('#no-item'));
					}

					// Append category to table
					polaris.append('tr', tbody, result.data.content, [], '', '', {'data-id':result.data.id});

					// Initialize polaris
					polaris.init();
	
					// Delete handler
					deleteBlogCat();

					// Handle edit
					editBlogCat();
				}
			});

			// Prevent default behavior
			return false;
		};
	}
};


/**
 * Delete blog category
 */
const deleteBlogCat = () => {
	deleteAPI('#blog-manage-category', 'blog-category', 'No category found!', 3);
};


/**
 * Edit blog category
 */
const editBlogCat = () => {
	const table = document.querySelector("#blog-manage-category");
	if (table) {
		const selectors = table.querySelectorAll('.edit-category');

		// Handle selectors
		selectors.forEach(selector => {
			// Handle click
			selector.onclick = () => {
				const id = selector.dataset.id;

				// Find the coresponding category td
				const categories = table.querySelectorAll('.post-category');
				let category;
				categories.forEach(elem => {
					if (elem.dataset.id == id) category = elem;
				});

				// Check category
				if (category) {
					// Fetch the current name
					const oldName = category.innerHTML;

					// Make categroy editable
					category.setAttribute('contenteditable', true);
					category.focus();

					// Keywboard key handler
					category.onkeydown = event => {
						// Enter key handler
						if (event.keyCode == 13) {
							// Force blure
							category.blur();
						}
					};

                    // Leave focus handler
                    category.onblur = () => {
                        // Remove editable attribute
                        category.removeAttribute('contenteditable');

                        // Required info
                        const itemId   = category.dataset.id;
                        const itemName = category.innerHTML;
                        const action   = table.dataset.action;
                        const method   = 'put';

						// Check the new name
						if (itemName != oldName) {
							// Edit category API
							fetch(action, {
								method: method,
								headers: new Headers({
									'Content-Type': 'application/json'
								}),
								body: JSON.stringify({
									edit_blog_cat: true,
									itemId:        itemId,
									itemName:      itemName
								})
							})
							.then(response => response.json())
							.then(result => {
								// Alert result message
								polaris.alert(result.message, "fadeInTop", "fadeOutBottom", result.status, alert, true, 750, 0);
	
								// On success
								if (result.status == 'success') {
									// Update the category URL
									const views = table.querySelectorAll('.view-category');
									let view;
									views.forEach(elem => {
										if (elem.dataset.id == id) view = elem;
									});
									view.setAttribute('href', result.url);
								}
								// On error
								else {
									// Reset the name
									category.innerHTML = oldName;
								}
							});
						}

                    };
				}
			};
		});
	}
};


/**
 * Blog Add & Edit Post
 */
const addEditPost = () => {
	const addPost = document.querySelector('#add-post');
	const editpost = document.querySelector('#edit-post');
	if (addPost || editpost) {
		// Source type
		const pageAPI  		 = document.querySelector("#page-api");
		const userAccess	 = document.querySelector("#field-access");
		const postType   	 = document.querySelector("#field-type");
		const postImportance = document.querySelector("#field-importance");
		const postStatus  	 = document.querySelector("#field-status");
		const postPublish  	 = document.querySelector("#field-publish");
		const uploadStorage  = window.localStorage;
		const uploadSettings = JSON.parse(uploadStorage.getItem("upload-settings"));
		let cover	  		 = document.querySelector('#image-file');
		let audio	  		 = document.querySelector('#audio-file');
		let video	  		 = document.querySelector('#video-file');
		let youtube		 	 = document.querySelector('#youtube-url');
		let uploadError;

		// Post Type
		postType.onchange = function() {
			document.querySelector("#post-type").value = this.value;

			// Regular
			if (this.value == 0) {
				audio.classList.add('display-none');
				video.classList.add('display-none');
				youtube.classList.add('display-none');
			}
			// Audio
			else if (this.value == 1) {
				audio.classList.remove('display-none');
				video.classList.add('display-none');
				youtube.classList.add('display-none');
			}
			// Video
			else if (this.value == 2) {
				audio.classList.add('display-none');
				video.classList.remove('display-none');
				youtube.classList.add('display-none');
			}
			// Youtube
			else if (this.value == 3) {
				audio.classList.add('display-none');
				video.classList.add('display-none');
				youtube.classList.remove('display-none');
			}
		};

		// User access
		userAccess.onchange = function() {
			document.querySelector("#user-access").value = this.value;
		};

		// Post importance
		postImportance.onchange = function() {
			document.querySelector("#post-importance").value = this.value;
		};

		// Post status
		postStatus.onchange = function() {
			// Active
			if (this.checked) {
				document.querySelector("#post-status").value = '1'
			}
			// Inactive
			else {
				document.querySelector("#post-status").value = '0'
			}
		};

		// Post publish
		postPublish.onblur = function() {
			document.querySelector("#post-publish").value = this.value;
		};

		// Add page submition
		if (addPost) {
			addPost.onsubmit = () => {
				uploadError = false;
				cover	  	= document.querySelector('#field-cover');
				audio	  	= document.querySelector('#field-audio');
				video	  	= document.querySelector('#field-video');
				youtube 	= document.querySelector('#field-youtube');

				// Check Cover
				const coverSize = cover.files[0].size; 
				const coverExt  = '.' + cover.files[0].name.split('.')[cover.files[0].name.split('.').length - 1];
	
				// File extension error
				if (!uploadSettings['image_types'].includes(coverExt)) {
					polaris.alert('Cover image extension error!', 'fadeInTop', 'fadeOutBottom', 'warning', '', true, 750, 0);
					cover.focus();
					uploadError = true;
				}
				// File size error
				else if (coverSize > uploadSettings['image_size']) {
					polaris.alert('Cover image size error!', 'fadeInTop', 'fadeOutBottom', 'warning', '', true, 750, 0);
					cover.focus();
					uploadError = true;
				}
	
				// Check Audio
				if (postType.value === '1' && !uploadError) {
					if (audio.files.length) {
						const audioSize = audio.files[0].size; 
						const audioExt  = '.' + audio.files[0].name.split('.')[audio.files[0].name.split('.').length - 1];
		
						// File extension error
						if (!uploadSettings['audio_types'].includes(audioExt)) {
							polaris.alert('Audio file extension error!', 'fadeInTop', 'fadeOutBottom', 'warning', '', true, 750, 0);
							audio.focus();
							uploadError = true;
						}
						// File size error
						else if (audioSize > uploadSettings['audio_size']) {
							polaris.alert('Audio file size error!', 'fadeInTop', 'fadeOutBottom', 'warning', '', true, 750, 0);
							audio.focus();
							uploadError = true;
						}
					}
					else {
						polaris.alert('Please select an audio file!', 'fadeInTop', 'fadeOutBottom', 'warning', '', true, 750, 0);
						audio.focus();
						uploadError = true;
					}
				}
				// Check Video
				else if (postType.value === '2' && !uploadError) {
					if (video.files.length) {
						const videoSize = video.files[0].size; 
						const videoExt  = '.' + video.files[0].name.split('.')[video.files[0].name.split('.').length - 1];
	
						// File extension error
						if (!uploadSettings['video_types'].includes(videoExt)) {
							polaris.alert('Video file extension error!', 'fadeInTop', 'fadeOutBottom', 'warning', '', true, 750, 0);
							video.focus();
							uploadError = true;
						}
						// File size error
						else if (videoSize > uploadSettings['video_size']) {
							polaris.alert('Video file size error!', 'fadeInTop', 'fadeOutBottom', 'warning', '', true, 750, 0);
							video.focus();
							uploadError = true;
						}
					}
					else {
						polaris.alert('Please select a video file!', 'fadeInTop', 'fadeOutBottom', 'warning', '', true, 750, 0);
						video.focus();
						uploadError = true;
					}
				}
				// Check youtube
				else if (postType.value === '3' && !youtube.value && !uploadError) {
					polaris.alert('Youtube URL is required!', 'fadeInTop', 'fadeOutBottom', 'warning', '', true, 750, 0);
					youtube.focus();
					uploadError = true;
				}
		
				// Handle form submition
				if (!uploadError) {
					submitForm(addPost)
					.then((result) => {
						// On success
						if (result.status) {
							// Reset form
							addPost.reset();

							// Reset summernote
							$('.summernote').summernote('reset');

							// Post type
							if (postType.value !== '0') {
								postType.value = '0';
								postType.onchange();
							}

							// User access
							if (userAccess.value !== '4') {
								userAccess.value = '4';
								userAccess.onchange();
							}

							// Post importance
							if (postImportance.value !== '0') {
								postImportance.value = '0';
								postImportance.onchange();
							}

							// Post status
							if (!postStatus.checked) postStatus.click();

							// Scroll to top
							document.querySelector('.partials').scrollIntoView();
						}
					});
				}

				// Prevent default behavior
				return false;
			};
		}

		// Edit page
		if (editpost) {
			editpost.onsubmit = () => {
				uploadError = false;
				cover	  	= document.querySelector('#field-cover');
				audio	  	= document.querySelector('#field-audio');
				video	  	= document.querySelector('#field-video');
				youtube 	= document.querySelector('#field-youtube');
	
				// Check Cover
				if (cover) {
					if (cover.files.length) {
						const coverSize = cover.files[0].size; 
						const coverExt  = '.' + cover.files[0].name.split('.')[cover.files[0].name.split('.').length - 1];
			
						// File extension error
						if (!uploadSettings['image_types'].includes(coverExt)) {
							polaris.alert('Cover image extension error!', 'fadeInTop', 'fadeOutBottom', 'warning', '', true, 750, 0);
							cover.focus();
							uploadError = true;
						}
						// File size error
						else if (coverSize > uploadSettings['image_size']) {
							polaris.alert('Cover image size error!', 'fadeInTop', 'fadeOutBottom', 'warning', '', true, 750, 0);
							cover.focus();
							uploadError = true;
						}
					}
				}
	
				// Check Audio
				if (audio && !uploadError) {
					if (audio.files.length) {
						const audioSize = audio.files[0].size; 
						const audioExt  = '.' + audio.files[0].name.split('.')[audio.files[0].name.split('.').length - 1];
			
						// File extension error
						if (!uploadSettings['audio_types'].includes(audioExt)) {
							polaris.alert('Audio file extension error!', 'fadeInTop', 'fadeOutBottom', 'warning', '', true, 750, 0);
							audio.focus();
							uploadError = true;
						}
						// File size error
						else if (audioSize > uploadSettings['audio_size']) {
							polaris.alert('Audio file size error!', 'fadeInTop', 'fadeOutBottom', 'warning', '', true, 750, 0);
							audio.focus();
							uploadError = true;
						}
					}
				}
				// Check Video
				else if (video && !uploadError) {
					if (video.files.length) {
						const videoSize = video.files[0].size; 
						const videoExt  = '.' + video.files[0].name.split('.')[video.files[0].name.split('.').length - 1];
			
						// File extension error
						if (!uploadSettings['video_types'].includes(videoExt)) {
							polaris.alert('Video file extension error!', 'fadeInTop', 'fadeOutBottom', 'warning', '', true, 750, 0);
							video.focus();
							uploadError = true;
						}
						// File size error
						else if (videoSize > uploadSettings['video_size']) {
							polaris.alert('Video file size error!', 'fadeInTop', 'fadeOutBottom', 'warning', '', true, 750, 0);
							video.focus();
							uploadError = true;
						}
					}
				}
				// Check youtube
				else if (youtube && !uploadError) {
					if (!youtube.value) {
						polaris.alert('Youtube URL is required!', 'fadeInTop', 'fadeOutBottom', 'warning', '', true, 750, 0);
						youtube.focus();
						uploadError = true;
					}
				}
	
				// Handle form submition
				if (!uploadError) {
					// Handle form submition
					submitForm(editpost)
					.then(result => {
						// Check next
						if (result.data.next) {
							// Update the dynamic link
							let link = document.querySelector("#dynamic-link");
							link.setAttribute('href', result.data.next);
		
							// Trigger the click event
							link.click();
						}
						else {
							if (cover) {
								if (cover.files.length) cover.value = '';
							}
							if (audio) {
								if (audio.files.length) audio.value = '';
							}
							if (video) {
								if (video.files.length) video.value = '';
							}
						}
					});
				}
	
				// Prevent default behavior
				return false;
			};
		}

	}
};


/**
 * Delete blog post
 */
const deleteBlogPost = () => {
	deleteAPI('#blog-manage-post', 'blog-post', 'No post found!', 4);
};
